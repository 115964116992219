<template>
    <div class="pt-32 flex flex-col items-center px-4">
        <van-cell-group class="md:w-1/2 w-full mb-8 shadow-md" inset v-for="(manager, index) in managers" :key="index">
            <profile-list-cell :user="manager" @click-icon="showProfile(manager)" :show-log="isSuperManager" />
        </van-cell-group>
    </div>
    <profile-viewer ref="viewProfileRef" :lang-code="'zh'" />
</template>

<script>
import {
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRouter,
    useRoute
} from "vue-router";
import {
    Cell,
    CellGroup,
    Dialog
} from "vant";
import Env from "@/logics/Envs.js";
import ListManager from "@/asyncs/ListManager.js";
import ProfileViewer from "@/components/dialog/ProfileViewer.vue";
import ProfileListCell from "@/components/list/ProfileListCell.vue";
import GetSession from "@/asyncs/GetSession.js";

export default {
    components: {
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [Dialog.Component.name]: Dialog.Component,
        ProfileViewer,
        ProfileListCell
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        store.commit("setPageTitle", "管理员列表");
        const route = useRoute();
        const router = useRouter;
        const managers = ref([]);
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                const list = await ListManager();
                managers.value = list[0];
            });
        });
        const viewProfileRef = ref(null);
        const showProfile = function (manager) {
            viewProfileRef.value.showUser(manager);
        };
        return {
            Env,
            managers,
            viewProfileRef,
            showProfile,
            isSuperManager: computed(() => store.state.user.super),
        };
    }
}
</script>
