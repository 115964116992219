import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function () {
    try {
        const res = await axios.get(Env.apiPath + "listManager");
        return [res.data.list, res.data.error];
    } catch (err) {
        return [null, err];
    }
}